import {api} from '../api'
import {bidStatuses} from './bidStatuses'

export const bidApi = api.injectEndpoints({
  endpoints: (build) => ({

    fetchBidPage: build.query({
      query(params) {
        const status = bidStatuses[params.status]
        return {
          url: `/bid/`,
          method: 'GET',
          params: {
            page: params.page,
            endpoint_id: params.endpoint_id,
            ...status?.params,
          },
        }
      },
    }),

    fetchBid: build.query({
      query(params) {
        return {
          url: `/bid/${params.id}/`,
          method: 'GET',
        }
      },
    }),

    createBid: build.query({
      query(payload) {
        return {
          url: `/bid/`,
          method: 'POST',
          data: {
            ...payload,
            car_id: payload.car ? payload.car.id : undefined,
            seller_id: payload.seller ? payload.seller.id : undefined,
          },
        }
      },
    }),

    withdrawalRequestBid: build.query({
      query(payload) {
        return {
          url: `/withdrawal-request/`,
          method: 'POST',
          data: {
            bid_id: payload.bid.id,
            bid_requisite_id: payload.requisite.id,
          },
        }
      },
    }),

    updateBid: build.query({
      query(payload) {
        const bid = {
          ...payload,
          item_list: payload.item_list.map((item, index) => ({
            order: item.order,
            catalogue_item_id: item.catalogue_item.id,
            weediness: item.weediness,
            weight_gross: item.weight_gross,
            weight_net: item.weight_net,
            weight_tare: item.weight_tare,
            catalogue_price_per_unit: item.catalogue_price_per_unit,
            price_per_unit: item.price_per_unit,
          })),
          car_id: payload.car ? payload.car.id : undefined,
          seller_id: payload.seller ? payload.seller.id : undefined,

          endpoint: undefined,
          seller: undefined,
          car: undefined,
        }
        return {
          url: `/bid/${payload.id}/`,
          method: 'PUT',
          data: bid,
        }
      },
    }),
  }),
})
