const defaultBidBlocks = {
  type: 'default',
  scrapPhotos: {
    display: true,
    // required: true
  },
  scrapList: {
    display: true,
  },
  seller: {
    display: true,
    type: 'modal', // modal, inline
    mainPhoto: {display: true},
    registrationPhoto: {display: true},
  },
  car: {
    display: true,
  },
  withdrawal: {
    display: true,
  },
  offer: {
    display: true,
  }
}

const shortBidBlocks = {
  type: 'short',
  scrapPhotos: {
    display: false,
  },
  scrapList: {
    display: false,
  },
  seller: {
    type: 'inline', // modal, inline
    display: true,
    mainPhoto: {display: true},
    registrationPhoto: {display: false},
  },
  car: {
    display: false,
  },
  withdrawal: {
    display: true,
  },
  offer: {
    display: false,
  }
}

const lightBidBlocks = {
  type: 'light',
  scrapPhotos: {
    display: true,
  },
  scrapList: {
    display: true,
  },
  seller: {
    display: false,
    preset: true,
    type: 'inline', // modal, inline
    mainPhoto: {display: true},
    registrationPhoto: {display: true},
  },
  car: {
    display: true,
  },
  withdrawal: {
    display: true,
  },
  offer: {
    display: true,
  }
}

const presets = {
  default: defaultBidBlocks,
  short: shortBidBlocks,
  light: lightBidBlocks,
}

export const bidBlocks = presets[process.env.REACT_APP_HMETAL_FLOW] ? presets[process.env.REACT_APP_HMETAL_FLOW] : presets.default
