// scrollbar
import 'simplebar-react/dist/simplebar.min.css';

// image
import 'react-lazy-load-image-component/src/effects/blur.css'

// ----------------------------------------------------------------------
import {Provider} from 'react-redux'
import {dispatch, store} from './store/store'
// routes
import Router from 'src/routes/sections'
// theme
import ThemeProvider from 'src/theme'
// hooks
import {useScrollToTop} from 'src/hooks/use-scroll-to-top'
// components
import ProgressBar from 'src/components/progress-bar'
import {MotionLazy} from 'src/components/animate/motion-lazy'
import {SettingsProvider, SettingsDrawer} from 'src/components/settings'
// auth
import {AuthProvider, AuthConsumer} from 'src/auth/context/jwt'
import {useNavigate} from 'react-router-dom'
import {useEffect} from 'react'
import {appSlice} from './store/app/appSlice'
//
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ru from 'date-fns/locale/ru'
import InfoMessages from './utils/InfoMessages'
import {network} from './utils/network'

import './externals/posthog'

// ----------------------------------------------------------------------

console.log(process.env.REACT_APP_VERSION)

export default function App() {
  useScrollToTop();
  const navigate = useNavigate();

  useEffect(() => {
    network.navigate = navigate
    dispatch(appSlice.actions.restore())
  }, [])

  return (
    <Provider store={store}>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={ru}
      >
        <AuthProvider>
          <SettingsProvider
            defaultSettings={{
              themeMode: 'light', // 'light' | 'dark'
              themeDirection: 'ltr', //  'rtl' | 'ltr'
              themeContrast: 'default', // 'default' | 'bold'
              themeLayout: 'vertical', // 'vertical' | 'horizontal' | 'mini'
              themeColorPresets: 'default', // 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'
              themeStretch: false,
            }}
          >
            <ThemeProvider>
              <MotionLazy>
                <SettingsDrawer/>
                <ProgressBar/>
                <AuthConsumer>
                  <InfoMessages />
                  <Router/>
                </AuthConsumer>
              </MotionLazy>
            </ThemeProvider>
          </SettingsProvider>
        </AuthProvider>
      </LocalizationProvider>
    </Provider>
  );
}
