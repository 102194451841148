import {Suspense} from 'react'
import ReactDOM from 'react-dom/client'
import {BrowserRouter} from 'react-router-dom'
import {HelmetProvider} from 'react-helmet-async'
import "yet-another-react-lightbox/styles.css"
import "./theme/style.css"

import App from './App'
import * as Sentry from '@sentry/react'
import {appConfig} from './config/appConfig'

if (appConfig.environment.REACT_APP_SENTRY_DSB) {
  Sentry.init({
    dsn: appConfig.environment.REACT_APP_SENTRY_DSB,
  })
}

if (appConfig.environment.REACT_APP_OMNIDESK_WIDGET_ID) {
  void function (e, o) {
    window.omni = window.omni ?? []
    window.omni.push(o);
    o.g_config = {widget_id: appConfig.environment.REACT_APP_OMNIDESK_WIDGET_ID};
    o.email_widget = o.email_widget || {};
    let w = o.email_widget;
    w.readyQueue = [];
    o.config = function (e) {
      this.g_config.user = e
    };
    w.ready = function (e) {
      this.readyQueue.push(e)
    };
    let r = e.getElementsByTagName("script")[0];
    let c = e.createElement("script");
    c.type = "text/javascript"
    c.async = !0
    c.src = "https://omnidesk.ru/bundles/acmesite/js/cwidget0.2.min.js"
    r.parentNode.insertBefore(c, r)
  }(document, [])
}

if (appConfig.environment.REACT_APP_CHATRA_GROUP_ID && appConfig.environment.REACT_APP_CHATRA_ID) {
  window.ChatraGroupID = appConfig.environment.REACT_APP_CHATRA_GROUP_ID

  void function (d, w, c) {
    w.ChatraID = appConfig.environment.REACT_APP_CHATRA_ID
    var s = d.createElement('script');
    w[c] = w[c] || function () {
      (w[c].q = w[c].q || []).push(arguments);
    };
    s.async = true;
    s.src = 'https://call.chatra.io/chatra.js';
    if (d.head) d.head.appendChild(s);
  }(document, window, 'Chatra');
}

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <HelmetProvider>
    <BrowserRouter>
      <Suspense>
        <App/>
      </Suspense>
    </BrowserRouter>
  </HelmetProvider>
)
