import { takeLatest, all, call, put, select } from "redux-saga/effects"
import { requisiteSlice } from './requisiteSlice'
import { network } from '../../utils/network'
import { appSlice } from '../app/appSlice'

function* create({ payload }) {

}

function* update({ payload }) {
  const { bid, requisiteData } = payload
  yield put(requisiteSlice.actions.setLoading({ type: 'requisite', loading: true }))

  const dictionary = yield select(store => store.app.dictionary)

  try {
    if (bid.requisite_list.length) {
      const requests = bid.requisite_list.map((requisite) => {
        return call(network.axios, { method: 'DELETE', url: `/bid-requisite/${requisite.id}/` })
      })
      yield all(requests)
    }
  } catch (e) {
    //
  }

  try {
    let emailRequisite = ''
    if (requisiteData.emailRequired && requisiteData.email) {
      const response = yield network.axios({
        method: 'POST',
        url: '/bid-requisite/',
        data: {
          "bid_id": bid.id,
          "bid_requisite_type_id": dictionary.bidRequisites.email.id,
          "requisite": requisiteData.email,
        },
      })
      emailRequisite = response.data
    }

    const response = yield network.axios({
      method: 'POST',
      url: '/bid-requisite/',
      data: {
        "bid_id": bid.id,
        "bid_requisite_type_id": dictionary.bidRequisites[requisiteData.type].id,
        "requisite": requisiteData[requisiteData.type],
        "bank_code": requisiteData.type === "phone" ? requisiteData.bank_code.value : undefined,
        "bank_name": requisiteData.type === "phone" ? requisiteData.bank_code.label : undefined,
        // "receiver_name": requisiteData.type === "phone" ? requisiteData.receiver_name : undefined,

        receiver_first_name: requisiteData.type === "phone" ? (requisiteData.receiver_first_name ? requisiteData.receiver_first_name : undefined) : undefined,
        receiver_last_name: requisiteData.type === "phone" ? (requisiteData.receiver_last_name ? requisiteData.receiver_last_name : undefined) : undefined,
        receiver_middle_name: requisiteData.type === "phone" ? (requisiteData.receiver_middle_name ? requisiteData.receiver_middle_name : undefined) : undefined,

        // "spb_payments_check_receiver_enabled": requisiteData.type === "phone" ? requisiteData.spb_payments_check_receiver_enabled : undefined,
      },
    })
    const requisite = [response.data]
    if (emailRequisite) {
      requisite.push(emailRequisite)
    }

    yield put(requisiteSlice.actions.setRequisite(requisite))
  } catch (e) {
    console.log('e', e)
    yield put(appSlice.actions.addMessage({
      type: 'error',
      text: 'Что-то пошло не так',
    }))
  }

  yield put(requisiteSlice.actions.setLoading({ type: 'requisite', loading: false }))
}

function* clear({ payload }) {
  yield put(requisiteSlice.actions.setLoading({ type: 'requisite', loading: true }))
  yield put(requisiteSlice.actions.setRequisite(null))
  yield put(requisiteSlice.actions.setLoading({ type: 'requisite', loading: false }))
}


export function* requisiteSagas() {
  yield takeLatest(requisiteSlice.actions.create.type, create)
  yield takeLatest(requisiteSlice.actions.update.type, update)
  yield takeLatest(requisiteSlice.actions.clear.type, clear)
}
