export const bankCodes = {
  "100000000111": "Сбербанк",
  "100000000004": "Т-Банк",
  "100000000008": "Альфа Банк",
  "100000000005": "ВТБ",
  "100000000007": "Райффайзен Банк",
  "100000000065": "ТОЧКА (ФК ОТКРЫТИЕ)",
  "100000000001": "Газпромбанк",
  "100000000202": "Норвик Банк",
  "100000000201": "Банк Кремлевский",
  "100000000206": "Томскпромстройбанк",
  "100000000205": "Банк Заречье",
  "100000000203": "МЕЖДУНАРОДНЫЙ ФИНАНСОВЫЙ КЛУБ",
  "100000000208": "Северный Народный Банк",
  "100000000059": "Центр-инвест",
  "100000000058": "ВЛАДБИЗНЕСБАНК",
  "100000000056": "КБ Хлынов",
  "100000000062": "НОКССБАНК",
  "100000000183": "ГТ банк",
  "100000000182": "Банк Объединенный капитал",
  "100000000187": "Банк РЕСО Кредит",
  "100000000066": "Земский банк",
  "100000000064": "Кредит Урал Банк",
  "100000000185": "Нацинвестпромбанк",
  "100000000069": "СДМ-Банк",
  "100000000189": "ТАТСОЦБАНК",
  "100000000194": "РУСНАРБАНК",
  "100000000193": "КБ Стройлесбанк",
  "100000000071": "НС Банк",
  "100000000070": "Датабанк",
  "100000000191": "КБЭР Банк Казани",
  "100000000197": "Трансстройбанк",
  "100000000195": "Кузнецкбизнесбанк",
  "100000000199": "ИШБАНК",
  "100000000080": "Алмазэргиэнбанк",
  "100000000084": "РосДорБанк",
  "100000000083": "Дальневосточный банк",
  "100000000082": "Банк ДОМ.РФ",
  "100000000081": "Форштадт",
  "100000000088": "СКБ Примсоцбанк",
  "100000000087": "Банк ПСКБ",
  "100000000086": "ЭЛПЛАТ",
  "100000000091": "БАНК СНГБ",
  "100000000090": "Банк Екатеринбург",
  "100000000095": "АБ РОССИЯ",
  "100000000094": "ЧЕЛЯБИНВЕСТБАНК",
  "100000000093": "Углеметбанк",
  "100000000026": "Уралсиб",
  "100000000092": "БыстроБанк",
  "100000000099": "КБ Модульбанк",
  "100000000098": "КБ РостФинанс",
  "100000000015": "Банк ФК Открытие",
  "100000000136": "МЕТКОМБАНК",
  "100000000014": "Банк Русский Стандарт",
  "100000000135": "Банк Акцепт",
  "100000000013": "Совкомбанк",
  "100000000134": "НБД-Банк",
  "100000000012": "Росбанк",
  "100000000139": "КБ ЭНЕРГОТРАНСБАНК",
  "100000000017": "МТС-Банк",
  "100000000016": "Почта Банк",
  "100000000258": "АИКБ Енисейский объединенный банк",
  "100000000022": "ЮМани",
  "100000000142": "УРАЛПРОМБАНК",
  "100000000020": "Россельхозбанк",
  "100000000140": "МБ Банк",
  "100000000025": "МКБ (Московский кредитный банк)",
  "100000000146": "КОШЕЛЕВ-БАНК",
  "100000000144": "Тимер Банк",
  "100000000029": "Банк Санкт-Петербург",
  "100000000028": "Банк АВАНГАРД",
  "100000000027": "Кредит Европа Банк (Россия)",
  "100000000148": "СИНКО-БАНК",
  "100000000154": "Банк Аверс",
  "100000000153": "Банк Венец",
  "100000000031": "УБРиР",
  "100000000152": "Тольяттихимбанк",
  "100000000030": "ЮниКредит Банк",
  "100000000151": "Урал ФД",
  "100000000037": "ГЕНБАНК",
  "100000000158": "Банк ИТУРУП",
  "100000000034": "ТРАНСКАПИТАЛБАНК",
  "100000000159": "Энергобанк",
  "100000000040": "Банк ФИНАМ",
  "100000000161": "КБ ЛОКО-Банк",
  "100000000160": "ЮГ-Инвестбанк",
  "100000000044": "Экспобанк",
  "100000000043": "Газэнергобанк",
  "100000000163": "Банк Снежинский",
  "100000000169": "Банк СКС",
  "100000000047": "Абсолют Банк",
  "100000000046": "Металлинвестбанк",
  "100000000045": "Банк ЗЕНИТ",
  "100000000166": "СИБСОЦБАНК",
  "100000000049": "Банк ВБРР",
  "100000000172": "Банк Развитие-Столица",
  "100000000171": "МОРСКОЙ БАНК",
  "100000000170": "Банк Интеза",
  "100000000176": "МОСКОМБАНК",
  "100000000174": "Первый Инвестиционный Банк",
  "100000000052": "Банк Левобережный",
  "100000000173": "Таврический Банк",
  "100000000213": "Джей энд Ти Банк (АО)",
  "100000000211": "Банк АЛЕКСАНДРОВСКИЙ",
  "100000000217": "ФОРА-БАНК",
  "100000000215": "ВУЗ-банк",
  "100000000102": "Банк Агророс",
  "100000000223": "СОЦИУМ БАНК",
  "100000000222": "Новобанк",
  "100000000107": "АКИБАНК",
  "100000000228": "Прио-Внешторгбанк",
  "100000000106": "ЧЕЛИНДБАНК",
  "100000000227": "Банк БКФ",
  "100000000105": "Эс-Би-Ай Банк",
  "100000000230": "Солид Банк",
  "100000000235": "АКБ Держава",
  "100000000113": "Алеф-Банк",
  "100000000112": "ГАРАНТ-ИНВЕСТ БАНК",
  "100000000232": "Реалист Банк",
  "100000000118": "КБ АГРОПРОМКРЕДИТ",
  "100000000115": "НИКО-БАНК",
  "100000000125": "ГОРБАНК",
  "100000000246": "МСП Банк",
  "100000000003": "Банк Синара",
  "100000000124": "БАНК ОРЕНБУРГ",
  "100000000243": "Банк Национальный стандарт",
  "100000000122": "ИК Банк",
  "100000000129": "КБ АРЕСБАНК",
  "100000000006": "Ак Барс Банк",
  "100000000127": "Хакасский муниципальный банк",
  "100000000248": "ВНЕШФИНБАНК",
  "100000000126": "Банк Саратов",
  "100000000247": "Банк Раунд",
  "100000000011": "РНКБ Банк",
  "100000000253": "РН БАНК",
  "100000000010": "Промсвязьбанк",
  "100000000130": "Автоградбанк",
  "100000000273": "ОЗОН Банк",
  "100000000150": 'Яндекс Банк',
}

const bankCodesOptions = Object.keys(bankCodes).map(key => {
  return {
    label: bankCodes[key],
    value: key,
  }
})

export {bankCodesOptions}
