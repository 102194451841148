import {useDropzone} from 'react-dropzone'
// @mui
import {alpha} from '@mui/material/styles'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
// assets
import {UploadIllustration} from 'src/assets/illustrations'
//
import Iconify from '../iconify'
//
import RejectionFiles from './errors-rejection-files'
import MultiFilePreview from './preview-multi-file'
import SingleFilePreview from './preview-single-file'
import {useEffect, useState} from 'react'
import Lightbox from '../lightbox'

// ----------------------------------------------------------------------

export function UploadScrap({
                              disabled,
                              multiple = false,
                              error,
                              helperText,
                              //
                              file,
                              onDelete,
                              //
                              files,
                              thumbnail,
                              onUpload,
                              onRemove,
                              onRemoveAll,
                              sx,
                              hideUpload,
                              ...other
                            }) {
  const {getRootProps, getInputProps, isDragActive, isDragReject, fileRejections} = useDropzone({
    multiple,
    disabled,
    ...other,
  });

  const [showImages, setShowImages] = useState(false)
  const [images, setImages] = useState([])

  const hasFile = !!file && !multiple;
  const hasFiles = !!files && multiple && !!files.length;
  const hasError = isDragReject || !!error;

  useEffect(() => {
    if (multiple) {
      setImages(files.filter(i => i.preview).map(i => ({src: i.preview})))
    } else {
      if (file && file.preview) {
        setImages([{src: file.preview}])
      } else {
        setImages([])
      }
    }
  }, [files, file])

  const renderPlaceholder = (
    <Stack spacing={.25} alignItems="center" justifyContent="center" flexWrap="wrap">
      <Iconify
        icon="eva:cloud-upload-fill"
        width={32}
        color="#919EAB"
      />

      <Typography variant="body2" sx={{color: 'text.secondary'}}>
        Выбрать фото
      </Typography>
    </Stack>
  );

  const renderSinglePreview = (
    <SingleFilePreview
      file={file}
      onRemove={onRemove}
      disabled={disabled}
      imgUrl={typeof file === 'string' ? file : file?.preview
    }/>
  );

  const removeSinglePreview = hasFile && onDelete && (
    <IconButton
      size="small"
      onClick={onDelete}
      sx={{
        top: 16,
        right: 16,
        zIndex: 9,
        position: 'absolute',
        color: (theme) => alpha(theme.palette.common.white, 0.8),
        bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
        '&:hover': {
          bgcolor: (theme) => alpha(theme.palette.grey[900], 0.48),
        },
      }}
    >
      <Iconify icon="mingcute:close-line" width={18}/>
    </IconButton>
  );

  const renderMultiPreview = hasFiles && (
    <>
      <Box sx={{my: 2}}>
        <Box sx={{mx: -0.5}}>
          <MultiFilePreview
            files={files}
            thumbnail={thumbnail}
            onRemove={onRemove}
            disabled={disabled}
            setShowImages={setShowImages}
          />
        </Box>
      </Box>

      <Stack direction="row" justifyContent="flex-end" spacing={1.5}>
        {/*{onRemoveAll && (*/}
        {/*  <Button color="inherit" variant="outlined" size="small" onClick={onRemoveAll}>*/}
        {/*    Remove All*/}
        {/*  </Button>*/}
        {/*)}*/}

        {/*{onUpload && (*/}
        {/*  <Button*/}
        {/*    size="small"*/}
        {/*    variant="contained"*/}
        {/*    onClick={onUpload}*/}
        {/*    startIcon={<Iconify icon="eva:cloud-upload-fill"/>}*/}
        {/*  >*/}
        {/*    Upload*/}
        {/*  </Button>*/}
        {/*)}*/}
      </Stack>
    </>
  );

  const overridden = {}

  if (disabled) {
    overridden.onClick = () => {
      if (images.length) {
        setShowImages(1)
      }
    }
  }

  return (
    <Box sx={{width: 1, position: 'relative', ...sx}}>

      <Lightbox
        open={!!showImages}
        close={() => setShowImages(false)}
        slides={images}
        index={showImages ? showImages - 1 : 0}
        disabledZoom={true}
        disabledTotal={true}
        disabledVideo={true}
        disabledCaptions={true}
        disabledSlideshow={true}
        disabledThumbnails={true}
        disabledFullscreen={true}
      />

      {!hideUpload && (
        <Box
          {...getRootProps()}
          sx={{
            p: 1,
            outline: 'none',
            borderRadius: 1,
            cursor: 'pointer',
            overflow: 'hidden',
            position: 'relative',
            bgcolor: (theme) => alpha(theme.palette.grey[500], 0.08),
            border: (theme) => `1px dashed ${alpha(theme.palette.grey[500], 0.2)}`,
            transition: (theme) => theme.transitions.create(['opacity', 'padding']),
            '&:hover': {
              opacity: 0.72,
            },
            ...(isDragActive && {
              opacity: 0.72,
            }),
            ...(disabled && {
              opacity: multiple ? 0.48 : 1,
              pointerEvents: multiple ? 'none' : undefined,
            }),
            ...(hasError && {
              color: 'error.main',
              borderColor: 'error.main',
              bgcolor: (theme) => alpha(theme.palette.error.main, 0.08),
            }),
            ...(hasFile && {
              padding: 7.626,
            }),
          }}
          {...overridden}
        >
          <input {...getInputProps()}/>

          {hasFile ? renderSinglePreview : renderPlaceholder}
        </Box>
      )}

      {removeSinglePreview}

      {helperText && helperText}

      <RejectionFiles fileRejections={fileRejections}/>

      {renderMultiPreview}
    </Box>
  );
}
