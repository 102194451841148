import {put, takeLatest, select, takeEvery} from "redux-saga/effects"
import {bidSlice} from './bidSlice'
import {network} from '../../utils/network'
import {appSlice} from '../app/appSlice'

export const idMapping = {}

export function documentTempToID(tempId) {
  return idMapping[tempId] ? idMapping[tempId] : tempId
}

function* fetch({payload}) {
  yield put(bidSlice.actions.setLoading({type: 'bids', loading: true}))

  try {
    const {selectedEndpoint} = yield select(store => store.endpoint)

    const response = yield network.axios({
      method: 'GET',
      url: '/bid/',
      params: {
        page: payload.page,
        endpoint_id: selectedEndpoint.id,
      }
    })

    yield put(bidSlice.actions.setBids({
      data: response.data.results.map(b => ({
        ...b,
        status: b.is_approved === false && b.status === 'new' ? 'on_review' : b.status,
      })),
      count: response.data.count,
    }))
  } catch (e) {
    console.log('e', e)
    // yield put(authSlice.actions.logout())
  }
  yield put(bidSlice.actions.setLoading({type: 'bids', loading: false}))
}

function* edit({payload}) {
  yield put(bidSlice.actions.setSelectedBid(null))
  yield put(bidSlice.actions.setLoading({type: 'selectedBid', loading: true}))
  const {id} = payload

  try {
    const response = yield network.axios({
      method: 'GET',
      url: `/bid/${id}/`,
    })

    response.data.requisite_list = response.data.requisite_list.filter(req => req.is_active)
    response.data.document_list = response.data.document_list.filter(doc => doc.is_active)
    response.data.item_list.forEach(item => {
      item.catalogueItem = {
        ...item.catalogue_item.prices[0],
        ...item.catalogue_item,
      }
      item.catalogue_item = item.catalogueItem.name
      item.price = Number(item.price)
      // item.price_per_kg = Number(item.price_per_kg)
      item.weediness = Number(item.weediness)
      item.weight_gross = Number(item.weight_gross)
      item.weight_net = Number(item.weight_net)
      item.weight_tare = Number(item.weight_tare)
    })

    response.data.status = response.data.is_approved === false && response.data.status === 'new' ? 'on_review' : response.data.status

    yield put(bidSlice.actions.setSelectedBid(response.data))
  } catch (e) {
    console.log('e', e)
  } finally {
    yield put(bidSlice.actions.setLoading({type: 'selectedBid', loading: false}));
  }
}

function* create() {
  yield put(bidSlice.actions.setLoading({type: 'bids', loading: true}));

  const selectedEndpoint = yield select(store => store.endpoint.selectedEndpoint)

  try {
    const response = yield network.axios({
      method: 'POST',
      url: '/bid/',
      data: {
        "endpoint_id": selectedEndpoint.id,
      },
    })


    network.navigate(`/operations/edit/${response.data.id}`)
  } catch (e) {
    console.log('e', e)
  } finally {
    yield put(bidSlice.actions.setLoading({type: 'bids', loading: false}));
  }
}

function* update({payload}) {
  yield put(bidSlice.actions.setLoading({type: 'selectedBidUpdate', loading: true}))

  const bid = {
    ...payload,
    item_list: payload.item_list.map((item, index) => ({
      id: item.id,
      order: item.order,
      catalogue_item_id: item.catalogueItem.id,
      weediness: item.weediness,
      weight_gross: item.weight_gross,
      weight_net: item.weight_net,
      weight_tare: item.weight_tare,
      catalogue_price_per_unit: item.catalogue_price_per_unit,
      price_per_unit: item.price_per_unit,
      // price_per_kg: item.price_per_kg,
    })),
    car_id: payload.car ? payload.car.id : undefined,
    seller_id: payload.seller ? payload.seller.id : undefined,
  }

  try {
    const response = yield network.axios({
      method: 'PUT',
      url: `/bid/${payload.id}/`,
      data: bid,
    })

    response.data.requisite_list = response.data.requisite_list.filter(req => req.is_active)
    response.data.document_list = response.data.document_list.filter(doc => doc.is_active)
    response.data.item_list.forEach(item => {
      item.catalogueItem = {
        ...item.catalogue_item.prices[0],
        ...item.catalogue_item,
      }
      item.catalogue_item = item.catalogueItem.name
      item.price = Number(item.price)
      // item.price_per_kg = Number(item.price_per_kg)
      item.weediness = Number(item.weediness)
      item.weight_gross = Number(item.weight_gross)
      item.weight_net = Number(item.weight_net)
      item.weight_tare = Number(item.weight_tare)
    })

    response.data.status = response.data.is_approved === false && response.data.status === 'new' ? 'on_review' : response.data.status

    yield put(bidSlice.actions.selectedBidUpdate({
      data: {
        ...response.data,
        item_list: response.data.item_list,
      },
      error: null,
    }))

    yield put(bidSlice.actions.setLoading({type: 'selectedBidUpdate', loading: false}))
  } catch (e) {
    const {response} = e
    if (e.response.status === 400) {
      yield put(bidSlice.actions.selectedBidUpdate({
        error: {
          ...response.data,
        },
      }))
    } else {
      yield put(appSlice.actions.addMessage({
        type: 'error',
        text: 'Что-то пошло не так',
      }))
    }
    yield put(bidSlice.actions.setLoading({type: 'selectedBidUpdate', loading: false}))
  }
}

function* addDocument({payload}) {
  const formData = new FormData()

  const dictionary = yield select(store => store.app.dictionary)

  formData.append("bid_id", payload.bid.id);
  formData.append("file_document_type_id", dictionary.fileDocuments[payload.type].id);
  formData.append("file", payload.file);

  yield put(bidSlice.actions.setSelectedBidDocumentStatus({
    key: payload.file.tempId,
    value: {
      uploaded: false,
    },
  }))

  try {
    const response = yield network.axios({
      method: 'POST',
      url: '/bid-file-document/',
      data: formData,
      headers: {"Content-Type": "multipart/form-data"},
    })

    yield put(bidSlice.actions.setSelectedBidDocumentStatus({
      key: payload.file.tempId,
      value: {
        uploaded: true,
      },
    }))

    idMapping[payload.file.tempId] = response.data.id
  } catch (e) {
    console.log('e', e)
    yield put(appSlice.actions.addMessage({
      type: 'error',
      text: 'Что-то пошло не так',
    }))
  } finally {
  }
}

function* removeDocument({payload}) {
  try {
    let id = payload.file.tempId
    if (idMapping[payload.file.tempId]) {
      id = idMapping[payload.file.tempId]
    }

    yield network.axios({
      method: 'DELETE',
      url: `/bid-file-document/${id}/`,
    })

    delete idMapping[payload.file.tempId]
  } catch (e) {
    console.log('e', e)
    yield put(appSlice.actions.addMessage({
      type: 'error',
      text: 'Что-то пошло не так',
    }))
  } finally {

  }
}

function* withdrawalRequest({payload}) {
  const {bid} = payload
  yield put(bidSlice.actions.setLoading({type: 'selectedBidUpdate', loading: true}))
  yield put(bidSlice.actions.setLoading({type: 'withdrawalRequest', loading: true}))

  const filteredRequisiteList = bid.requisite_list.filter(r => r.requisite_type_name === 'pan' || r.requisite_type_name === 'phone')

  try {
    const response = yield network.axios({
      method: 'POST',
      url: `/withdrawal-request/`,
      data: {
        bid_id: bid.id,
        bid_requisite_id: filteredRequisiteList.length ? filteredRequisiteList.at(0).id : undefined,
      },
    })

    if (response.data.success === false) {
      yield put(appSlice.actions.addMessage({
        type: 'success',
        text: 'Операция отправлена на согласование',
      }))
    }

    network.navigate(`/operations/view/${bid.id}`, {replace: true})
  } catch (e) {
    console.log('e', e)
    if (e?.response?.data?.message) {
      yield put(appSlice.actions.addMessage({
        type: 'error',
        text: e?.response?.data?.message,
      }))
      return
    }
    yield put(appSlice.actions.addMessage({
      type: 'error',
      text: 'Что-то пошло не так',
    }))
  } finally {
    yield put(bidSlice.actions.setLoading({type: 'withdrawalRequest', loading: false}))
    yield put(bidSlice.actions.setLoading({type: 'selectedBidUpdate', loading: false}))
  }
}

function* prepareDocuments({payload}) {
  const {bid, itemClasses} = payload

  const group_item_list = []
  if (itemClasses && itemClasses.length) {
    const filteredClasses = itemClasses.filter(item => {
      return true
    })

    filteredClasses.forEach(i => {
      group_item_list.push({
        "scrap_class_id": i.scrap_class_id,
        "document_number": i.value_document_number ? i.value_document_number :  null,
        "description": i.value_description ? i.value_description : null,
      })
    })
  }

  yield put(bidSlice.actions.setLoading({type: 'preparedDocuments', loading: true}))
  yield put(bidSlice.actions.setLoading({type: 'selectedBidUpdate', loading: true}))

  yield put(bidSlice.actions.setPreparedDocuments(null))

  try {
    const response = yield network.axios({
      method: 'POST',
      url: `/bid/${bid.id}/prepare_documents/`,
      data: {
        bid_id: bid.id,
        group_item_list,
      },
    })

    const {document_list} = response.data
    const document = document_list.find(document => document.is_active && document.file_document.document_type_name === 'offer_full')

    yield put(bidSlice.actions.setPreparedDocuments({
      bid: response.data,
      file: document.file_document.file,
    }))
  } catch (e) {
    if (e?.response?.data?.message) {
      yield put(appSlice.actions.addMessage({
        type: 'error',
        text: e?.response?.data?.message,
      }))
      return
    }
    console.log('e', e)
    yield put(appSlice.actions.addMessage({
      type: 'error',
      text: 'Что-то пошло не так',
    }))
  } finally {
    yield put(bidSlice.actions.setLoading({type: 'preparedDocuments', loading: false}))
    yield put(bidSlice.actions.setLoading({type: 'selectedBidUpdate', loading: false}))
  }
}

function* approve({payload}) {
  const {bid} = payload
  yield put(bidSlice.actions.setLoading({type: 'selectedBidUpdate', loading: true}))

  try {
    const response = yield network.axios({
      method: 'POST',
      url: `/bid/${bid.id}/approve/`,
    })
    network.navigate(`/operations`)
  } catch (e) {
    if (e?.response?.data?.message) {
      yield put(appSlice.actions.addMessage({
        type: 'error',
        text: e?.response?.data?.message,
      }))
      return
    }
    yield put(appSlice.actions.addMessage({
      type: 'error',
      text: 'Что-то пошло не так',
    }))
  } finally {
    yield put(bidSlice.actions.setLoading({type: 'selectedBidUpdate', loading: false}))
  }
}

function* reject({payload}) {
  const {bid} = payload
  yield put(bidSlice.actions.setLoading({type: 'selectedBidUpdate', loading: true}))

  try {
    const response = yield network.axios({
      method: 'POST',
      url: `/bid/${bid.id}/reject/`,
    })
    network.navigate(`/operations`)
  } catch (e) {
    if (e?.response?.data?.message) {
      yield put(appSlice.actions.addMessage({
        type: 'error',
        text: e?.response?.data?.message,
      }))
      return
    }
    yield put(appSlice.actions.addMessage({
      type: 'error',
      text: 'Что-то пошло не так',
    }))
  } finally {
    yield put(bidSlice.actions.setLoading({type: 'selectedBidUpdate', loading: false}))
  }
}

export function* bidSagas() {
  yield takeLatest(bidSlice.actions.fetch.type, fetch)
  yield takeLatest(bidSlice.actions.create.type, create)
  yield takeLatest(bidSlice.actions.update.type, update)
  yield takeLatest(bidSlice.actions.edit.type, edit)

  yield takeEvery(bidSlice.actions.addDocument.type, addDocument)
  yield takeEvery(bidSlice.actions.removeDocument.type, removeDocument)

  yield takeLatest(bidSlice.actions.withdrawalRequest.type, withdrawalRequest)
  yield takeLatest(bidSlice.actions.prepareDocuments.type, prepareDocuments)

  yield takeLatest(bidSlice.actions.approve.type, approve)
  yield takeLatest(bidSlice.actions.reject.type, reject)
}
