
export const bidStatuses = {
  all: {
    value: 'all',
    name: 'Все',
    plural: 'Все',
    // textColor: '#737373',
    // icon: EditFilled,
    params: {}
  },
  new: {
    name: 'Новая',
    textColor: '#B76E00',
    color: 'info',
    // color: 'rgba(255, 171, 0, 0.16)',
    params: {
      status: 'new',
    }
  },
  accepted: {
    name: 'К выплате',
    textColor: '#B76E00',
    color: 'warning',
    // color: 'rgba(255, 171, 0, 0.16)',
    params: {
      status: 'accepted',
    }
  },
  on_review: {
    name: 'На проверке',
    textColor: '#B76E00',
    color: 'warning',
    // color: 'rgba(255, 171, 0, 0.16)',
    params: {
      status: 'new',
      is_approved: false,
    }
  },
  pending: {
    name: 'В ожидании',
    textColor: '#B76E00',
    color: 'warning',
    // color: 'rgba(255, 171, 0, 0.16)',
    params: {
      status: 'pending',
    }
  },
  done: {
    name: 'Успешная',
    textColor: '#118D57',
    color: 'success',
    // color: 'rgba(34, 197, 94, 0.16)',
    params: {
      status: 'done',
    }
  },
  rejected: {
    name: 'Отклонена',
    textColor: '#B71D18',
    color: 'error',
    // color: 'rgba(255, 86, 48, 0.16)',
    params: {
      status: 'rejected',
    }
  },
  error: {
    name: 'Ошибка',
    textColor: '#B71D18',
    color: 'error',
    // color: 'rgba(255, 86, 48, 0.16)',
  },
  params: {
    status: 'error',
  }
}

export const bidStatusesList = [
  bidStatuses.all,
  bidStatuses.new,
  bidStatuses.on_review,
  bidStatuses.pending,
  bidStatuses.done,
  bidStatuses.rejected,
  bidStatuses.error,
]

