import {createSlice} from "@reduxjs/toolkit"

const initialState = {
  requisite: {
    loading: false,
    data: null,
  }
}

const requisiteSlice = createSlice({
  name: 'requisite',
  initialState,
  reducers: {
    create(state, action) {

    },
    update(state, action) {

    },
    setRequisite(state, action) {
      const {payload} = action
      state.requisite.data = payload
    },
    setLoading(state, action) {
      const {payload} = action
      state[payload.type].loading = payload.loading
    },
    clear(state, action) {
      
    }
  },
})

const requisiteReducer = requisiteSlice.reducer

export {requisiteSlice, requisiteReducer}
