import {put, take, takeLatest} from "redux-saga/effects"
import {userSlice} from './userSlice'
import {network} from '../../utils/network'
import {appSlice} from '../app/appSlice'
import {endpointSlice} from '../endpoint/endpoingSlice'

function* restore() {
  yield put(userSlice.actions.setLoading(true))

  try {
    const tokens = JSON.parse(localStorage.getItem('tokens'))
    const permissions = JSON.parse(localStorage.getItem('permissions'))
    if (!tokens || !permissions) {
      yield put(userSlice.actions.logout())
      yield put(userSlice.actions.setLoading(false))
      return
    }

    const {access, refresh} = tokens
    if (!access || !refresh) {
      yield put(userSlice.actions.logout())
      yield put(userSlice.actions.setLoading(false))
      return
    }
    network.axiosUpdate(access, refresh)

    const balancesRes = yield network.axios({
      method: 'GET',
      url: '/user-balance/',
    })
    const balancesData = balancesRes.data
    const balances = balancesData

    const response = yield network.axios({
      method: 'GET',
      url: '/directories/',
    })
    yield put(appSlice.actions.setDirectories(response.data))
    yield put(endpointSlice.actions.fetch())
    yield take(endpointSlice.actions.selectEndpoint.type)

    yield put(userSlice.actions.setUser({
      user: {},
      tokens: {access, refresh},
      permissions,
      balances,
    }))
  } catch (e) {
    console.error(e)
  }

  yield put(userSlice.actions.setLoading(false))
}

function* login({payload}) {
  yield put(userSlice.actions.setLoading(true))
  const {username, password} = payload

  try {
    const tokenRes = yield network.axios({
      method: 'POST',
      url: '/token/',
      data: {
        username,
        password,
      }
    })
    const {access, refresh} = tokenRes.data
    network.axiosUpdate(access, refresh)

    const balancesRes = yield network.axios({
      method: 'GET',
      url: '/user-balance/',
    })
    const balancesData = balancesRes.data
    const balances = balancesData

    const permissionsRes = yield network.axios({
      method: 'GET',
      url: '/user-merchant/',
    })
    const permissionsData = permissionsRes.data

    const permissions = {
      user_id: permissionsData[0].user_id,
      user_name: permissionsData[0].user_name,
      merchant_settings: permissionsData[0].merchant_settings,
      endpoints: {},
    }

    permissionsData.forEach(permission => {
      permission.endpoint_permissions.forEach(ep => {
        if(!permissions.endpoints[ep.endpoint_id]) {
          permissions.endpoints[ep.endpoint_id] = {}
        }
        permissions.endpoints[ep.endpoint_id].merchant_id = permission.merchant_id
        const {user_id, endpoint_id, ...rest} = ep
        Object.entries(rest).forEach(([key, value]) => {
          if(value) {
            permissions.endpoints[ep.endpoint_id][key] = true
          } else {
            permissions.endpoints[ep.endpoint_id][key] = false
          }
        })
      })
    })

    const response = yield network.axios({
      method: 'GET',
      url: '/directories/',
    })
    yield put(appSlice.actions.setDirectories(response.data))

    yield put(endpointSlice.actions.fetch())
    yield take(endpointSlice.actions.selectEndpoint.type)

    localStorage.setItem('tokens', JSON.stringify({access, refresh}))
    localStorage.setItem('permissions', JSON.stringify(permissions))

    yield put(userSlice.actions.setUser({
      user: {},
      tokens: {access, refresh},
      permissions,
      balances,
    }))
  } catch (e) {
    console.error(e)
    yield put(userSlice.actions.logout())
  }

  yield put(userSlice.actions.setLoading(false))
}

function* logout() {
  localStorage.removeItem('tokens')
  localStorage.removeItem('permissions')
  yield put(userSlice.actions.setUser({
    user: null,
    tokens: null,
    permissions: null,
    balances: null,
  }))
}

export function* userSagas() {
  yield takeLatest(userSlice.actions.restore.type, restore)
  yield takeLatest(userSlice.actions.logout.type, logout)
  yield takeLatest(userSlice.actions.login.type, login)
}
