import {lazy, Suspense} from 'react';
import {Outlet} from 'react-router-dom';
// auth
import {AuthGuard} from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import {LoadingScreen} from 'src/components/loading-screen'
import ViewOperation from '../../pages/ViewOperation/ViewOperation'
import {bidBlocks} from '../../config/bidBlocks'

// ----------------------------------------------------------------------

// const IndexPage = lazy(() => import('src/pages/dashboard/one'));
// const PageTwo = lazy(() => import('src/pages/dashboard/two'));
// const PageThree = lazy(() => import('src/pages/dashboard/three'));
// const PageFour = lazy(() => import('src/pages/dashboard/four'));
// const PageFive = lazy(() => import('src/pages/dashboard/five'));
// const PageSix = lazy(() => import('src/pages/dashboard/six'));

const OperationsPage = lazy(() => import('src/pages/operations/OperationsPage'));

const EditOperation = lazy(() => import('src/pages/editOperation/EditOperation'));
const ShortOperation = lazy(() => import('src/pages/ShortOperation/ShortOperation'));
const LightOperation =  lazy(() => import('src/pages/LightOperation/LightOperation'));

let editOperation = <EditOperation />
let viewOperation = <ViewOperation />

switch (bidBlocks.type) {
  case 'short':
    editOperation = <ShortOperation />
    viewOperation = <ShortOperation />
    break
  case 'light':
    editOperation = <LightOperation />
    viewOperation = <LightOperation />
    break
}

export const dashboardRoutes = [
  {
    path: '/operations',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen/>}>
            <Outlet/>
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      {
        index: true,
        element: <OperationsPage/>,
      },
      {
        path: 'edit/:id',
        element: editOperation,
      },
      {
        path: 'view/:id',
        element: viewOperation,
      },
    ],
  },
];
