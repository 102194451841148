// https://developer.mozilla.org/en-US/docs/Web/Guide/Printing

function setPrint() {
  const closePrint = () => {
    document.body.removeChild(this)
  }
  this.contentWindow.onbeforeunload = closePrint
  this.contentWindow.onafterprint = closePrint
  this.contentWindow.print()
}

export function printExternalFile(url) {
  // const externalURL = new URL(url)
  // const currentURL= new URL(window.location.href)
  // window.open(currentURL.origin + externalURL.pathname, '_blank').focus()

  window.open(url, '_blank').focus()

  // const hideFrame = document.createElement("iframe")
  // hideFrame.onload = setPrint
  // hideFrame.style.display = "none"
  //
  // hideFrame.src = currentURL.origin + externalURL.pathname
  // document.body.appendChild(hideFrame)
}

