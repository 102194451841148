import axios from 'axios'
import {networkCodes} from '../config/networkCodes'
import {store} from '../store/store'
import {userSlice} from '../store/user/userSlice'

let isUpdatePromise = null

function updateToken(refreshToken) {
  return isUpdatePromise = new Promise(async res => {
    try {
      const response = await axios({
        url: (process.env.REACT_APP_API_URI ? process.env.REACT_APP_API_URI : "/") + '/token/refresh/',
        method: 'POST',
        data: {refresh: refreshToken}
      })
      const {access, refresh} = response.data
      localStorage.setItem('tokens', JSON.stringify({access, refresh}))
      res(response.data)
    } catch (e) {
      store.dispatch(userSlice.actions.logout())
      res()
    }
    isUpdatePromise = null
  })
}


function axiosUpdate(token, refresh) {
  const headers = {
    'Accept-Language': 'ru',
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  }

  if (token && refresh) {
    headers['Authorization'] = `Bearer ${token}`
  }

  const appAxios = axios.create({
    baseURL: process.env.REACT_APP_API_URI ? process.env.REACT_APP_API_URI : "/",
    headers,
  })

  if (token && refresh) {
    appAxios.interceptors.response.use(null, async error => {
      if (error.response.status === 401) {
        if (error.response?.data?.code === networkCodes.tokenNotValid) {

          const tokens = isUpdatePromise ? await isUpdatePromise : await updateToken(refresh)

          if (!tokens) {
            store.dispatch(userSlice.actions.logout())
            return Promise.reject(error)
          }
          network.axios = axiosUpdate(tokens.access, tokens.refresh)

          error.config.headers['Authorization'] = `Bearer ${tokens.access}`


          return network.axios.request(error.config)
        }
      }
      return Promise.reject(error)
    })
  }

  network.axios = appAxios

  return network.axios
}

const network = {
  axios,
  axiosUpdate,
  navigate: () => console.log('navigate fail'),
}

axiosUpdate()

export {network}
