import PropTypes from 'prop-types';
// @mui
import Box from '@mui/material/Box';
//
import Image from '../image';
import {CircularProgress} from '@mui/material'
import {useSelector} from 'react-redux'
import IconButton from '@mui/material/IconButton'
import {alpha} from '@mui/material/styles'
import Iconify from '../iconify'

// ----------------------------------------------------------------------

export default function SingleFilePreview({imgUrl = '', file, onRemove, disabled}) {
  const bidStore = useSelector(store => store.bid)
  const {selectedBidDocumentStatus} = bidStore

  const status = selectedBidDocumentStatus[file.tempId]
  if (!status) {
    return null
  }

  return (
    <Box
      sx={{
        p: 1,
        top: 0,
        left: 0,
        width: 1,
        height: 1,
        position: 'absolute',
      }}
    >
      {status.uploaded && (
        <Image
          alt="file preview"
          src={imgUrl}
          sx={{
            width: 1,
            height: 1,
            borderRadius: 1,
          }}
        />
      )}

      {!status.uploaded && (
        <Box
          sx={{
            // m: -1,
            top: 0,
            width: '100%',
            height: 124,
            overflow: 'hidden',
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      )}

      {onRemove && (
        <IconButton
          disabled={!status.uploaded || disabled}
          size="small"
          onClick={(event) => onRemove(file, event)}
          sx={{
            p: 0.5,
            top: 4,
            right: 4,
            position: 'absolute',
            color: 'common.white',
            bgcolor: (theme) => alpha(theme.palette.grey[900], 0.48),
            '&:hover': {
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
            },
          }}
        >
          <Iconify icon="mingcute:close-line" width={14} />
        </IconButton>
      )}

    </Box>
  );
}

SingleFilePreview.propTypes = {
  imgUrl: PropTypes.string,
  onRemove: PropTypes.func,
};
