import {Helmet} from 'react-helmet-async'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import {useSettingsContext} from '../../components/settings'
import React, {useCallback, useEffect, useState} from 'react'
import {Card, CardContent, Chip, Snackbar} from '@mui/material'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import {bidSlice} from '../../store/bid/bidSlice'
import {useDispatch, useSelector} from 'react-redux'
import {useParams} from 'react-router-dom'
import {bidStatuses} from '../../store/bid/bidStatuses'
import Divider from '@mui/material/Divider'
import {UploadScrap} from '../../components/uploadScrap/UploadScrap'
import {bankCodes} from '../../config/banks'
import {phoneBeautify} from '../../utils/phone'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'
import {printExternalFile} from '../../utils/printExternalFile'

export default function ViewOperation() {
  const settings = useSettingsContext()
  const dispatch = useDispatch()
  const params = useParams()

  const userPermission = useSelector(store => store.user?.permissions)
  const selectedEndpointId = useSelector(store => store.endpoint.selectedEndpoint?.id)
  const permissions = userPermission?.endpoints ? userPermission.endpoints[selectedEndpointId] : {}

  const countyList = useSelector(store => store.app.dictionary.countyList)
  const {selectedBid, selectedBidUpdate} = useSelector(store => store.bid)

  const loading = selectedBidUpdate.loading

  useEffect(() => {
    dispatch(bidSlice.actions.edit({id: params.id}))
  }, [])

  const [bid, setBid] = useState(null)
  const [files, setFiles] = useState([]);
  const [documentFiles, setDocumentFiles] = useState([]);
  const [offerFiles, setOfferFiles] = useState([]);

  useEffect(() => {
    if (selectedBid.data) {
      const offers = selectedBid.data.document_list.filter(document => document.is_active && document.file_document.document_type_name === 'offer')
      if (offerFiles) {
        setOfferFiles(offers)
      }
      const list = selectedBid.data.document_list
        .map(document => {
          dispatch(bidSlice.actions.setSelectedBidDocumentStatus({key: document.id, value: {uploaded: true}}))
          return {
            document,
            tempId: document.id,
            preview: document.file_document.file,
          }
        })
      setBid(selectedBid.data)
      setFiles([
        ...list.filter(i => i.document.file_document.document_type_name === 'scrap'),
      ])
      setDocumentFiles([
        ...list.filter(i => i.document.file_document.document_type_name === 'passport_main_page'),
        ...list.filter(i => i.document.file_document.document_type_name === 'passport_registration_page'),
        ...list.filter(i => i.document.file_document.document_type_name === 'offer_signed'),
      ])
    }
  }, [selectedBid.data])

  const approve = useCallback(() => {
    dispatch(bidSlice.actions.approve({bid}))
  }, [bid])

  const reject = useCallback(() => {
    dispatch(bidSlice.actions.reject({bid}))
  }, [bid])

  if (!bid) {
    return null
  }

  const {item_list, is_approved} = bid
  const passport = bid?.seller?.person_passport?.find(p => p.is_active)
  const county = countyList.find(c => c.code === passport?.country_code)

  const requisites = {
    pan: bid.requisite_list.find(r => r.requisite_type_name === 'pan')?.requisite,
    phone: bid.requisite_list.find(r => r.requisite_type_name === 'phone')?.requisite,
    email: bid.requisite_list.find(r => r.requisite_type_name === 'email')?.requisite,
  }
  let bank_name = null
  if (requisites.phone) {
    const r = bid.requisite_list.find(r => r.requisite_type_name === 'phone')
    bank_name = bankCodes[r.bank_code] ?? null
  }

  const selfCreated = bid.created_by === userPermission.user_id
  const canApprove = (selfCreated && permissions.is_bid_self_approver) || permissions.is_bid_approver

  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <Helmet>
        <title>Подтверждение</title>
      </Helmet>

      <Box mb={3}>
        <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
          <Typography variant="h6">
            {bid.id}
          </Typography>
          <Chip
            size="small"
            label={bidStatuses[bid.status].name}
            color={bidStatuses[bid.status].color}
          />
        </Box>
        <Typography variant="body2" sx={{color: 'text.secondary'}}>
          Создан {new Date(bid.updated_at).toLocaleTimeString()}, {new Date(bid.updated_at).toLocaleDateString()}
        </Typography>
      </Box>

      <Box mb={3}>
        <Card>
          <CardContent>
            <Box mb={2.5}>
              <Typography variant="h5">
                Список лома
              </Typography>
            </Box>
            {item_list.map((item, index) => {
              const unit = item.catalogueItem ? `, ${item.catalogueItem?.unit_of_measure}` : ''
              return (
                <Box key={item.id}>
                  {index > 0 && (
                    <Box mb={1.5}>
                      <Divider sx={{borderStyle: 'dashed'}}/>
                    </Box>
                  )}

                  <Box mb={1.5} sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <Typography variant="subtitle1">
                      {item.catalogue_item}
                    </Typography>
                  </Box>

                  <Box mb={1.5} sx={{display: 'flex', justifyContent: 'space-between'}}>
                    <Typography variant="body2">
                      {`Вес нетто${unit}`}
                    </Typography>
                    <Typography variant="body2" sx={{color: 'text.secondary'}}>
                      {item.weight_net}
                    </Typography>
                  </Box>

                  <Box mb={1.5} sx={{display: 'flex', justifyContent: 'space-between'}}>
                    <Typography variant="body2">
                      Цена за единицу
                    </Typography>
                    <Typography variant="body2" sx={{color: 'text.secondary'}}>
                      {item.price_per_unit}
                    </Typography>
                  </Box>

                  {item.price_per_unit !== item.catalogue_price_per_unit && (
                    <Box mb={1.5} sx={{display: 'flex', justifyContent: 'space-between'}}>
                      <Typography variant="body2">
                        Рекомендованная цена, руб.
                      </Typography>
                      <Typography variant="body2" sx={{color: 'text.secondary'}}>
                        {item.catalogue_price_per_unit}
                      </Typography>
                    </Box>
                  )}

                  <Box mb={1.5} sx={{display: 'flex', justifyContent: 'space-between'}}>
                    <Typography variant="body2">
                      Сумма, руб.
                    </Typography>
                    <Typography variant="body2" sx={{color: 'text.secondary'}}>
                      {item.price}
                    </Typography>
                  </Box>
                </Box>
              )
            })}
          </CardContent>
        </Card>
      </Box>

      <Box mb={3}>
        <Card>
          <CardContent sx={{background: '#EAF6FF'}}>
            <Box mb={2.5}>
              <Typography variant="h5">
                Итого
              </Typography>
            </Box>

            <Box mb={1.5} sx={{display: 'flex', justifyContent: 'space-between'}}>
              <Typography variant="body2">
                Вес нетто
              </Typography>
              <Typography variant="body2" sx={{color: 'text.secondary'}}>
                {Number(item_list.reduce((a, item) => a + Number(item.weight_net), 0))}
              </Typography>
            </Box>

            <Box mb={1.5} sx={{display: 'flex', justifyContent: 'space-between'}}>
              <Typography variant="body2">
                Позиций
              </Typography>
              <Typography variant="body2" sx={{color: 'text.secondary'}}>
                {item_list.length}
              </Typography>
            </Box>

            <Box mb={1.5}>
              <Divider sx={{borderStyle: 'dashed'}}/>
            </Box>

            <Box mb={1.5} sx={{display: 'flex', justifyContent: 'space-between'}}>
              <Typography variant="Subtitle1">
                Сумма (руб.)
              </Typography>
              <Typography variant="Subtitle1">
                {bid.price}
              </Typography>
            </Box>

          </CardContent>
        </Card>
      </Box>

      {files.length > 0 && (
        <Box mb={3}>
          <Card>
            <CardContent>
              <Box mb={2.5}>
                <Typography variant="h5">
                  Фотографии лома
                </Typography>
              </Box>
              <UploadScrap
                multiple
                accept={{'image/*': []}}
                thumbnail={true}
                files={files}
                disabled={true}
                hideUpload={true}
              />
            </CardContent>
          </Card>
        </Box>
      )}

      {offerFiles.length > 0 && (
        <Box mb={3}>
          <Card>
            <CardContent>
              <Box>
                <Typography variant="h5">
                  Документы
                </Typography>
              </Box>
              {offerFiles.map((document) => {
                return (
                  <Box mt={2} key={document.id}>
                    <Button
                      size="large"
                      style={{justifyContent: 'flex-start'}}
                      fullWidth={true}
                      variant="outlined"
                      startIcon={<InsertDriveFileIcon/>}
                      endIcon={(
                        <FileDownloadOutlinedIcon style={{position: 'absolute', right: 12, top: 12}}/>
                      )}
                      onClick={() => printExternalFile(document.file_document.file)}
                    >
                      {document.document_number}
                    </Button>
                  </Box>
                )
              })}
            </CardContent>
          </Card>
        </Box>
      )}

      {documentFiles.length > 0 && (
        <Box mb={3}>
          <Card>
            <CardContent>
              <Box mb={2.5}>
                <Typography variant="h5">
                  Фотографии документов
                </Typography>
              </Box>
              <UploadScrap
                multiple
                accept={{'image/*': []}}
                thumbnail={true}
                files={documentFiles}
                disabled={true}
                hideUpload={true}
              />
            </CardContent>
          </Card>
        </Box>
      )}

      {passport && (
        <Box mb={3}>
          <Card>
            <CardContent>
              <Box my={1}>
                <Typography mb={2} variant="h5">
                  {`${passport.first_name} ${passport.middle_name} ${passport.last_name}`}
                </Typography>

                <Typography my={.5} variant="body2">
                  Паспорт{county ? ` ${county.label}` : ''}, {passport.series} {passport.number}
                </Typography>

                {(passport.issued_by || passport.date_of_issue) && (
                  <Typography my={.5} variant="body2">
                    выдан {passport.issued_by ?? ''} {new Date(passport.date_of_issue).toLocaleDateString()}
                  </Typography>
                )}

                {passport.subdivision_code && (
                  <Typography my={.5} variant="body2">
                    код подразделения {passport.subdivision_code}
                  </Typography>
                )}
              </Box>
            </CardContent>
          </Card>
        </Box>
      )}

      {bid.requisite_list.length > 0 && (
        <Box mb={3}>
          <Card>
            <CardContent>
              <Box mb={2.5}>
                <Typography variant="h5">
                  Выплата
                </Typography>
              </Box>
              <Box my={1}>
                {requisites.pan && (
                  <Typography variant="subtitle2">
                    {requisites.pan.match(/.{1,4}/g).join(' ')}
                  </Typography>
                )}
                {requisites.phone && (
                  <Typography variant="subtitle2">
                    {phoneBeautify(requisites.phone)}
                    {bank_name ? `, ${bank_name}` : null}
                  </Typography>
                )}
                {requisites.email && (
                  <Typography variant="subtitle2">
                    {(requisites.pan || requisites.phone) ? ',' : null} {requisites.email}
                  </Typography>
                )}
              </Box>
            </CardContent>
          </Card>
        </Box>
      )}

      {is_approved || !canApprove ? (
        <Stack direction="row" spacing={2} mt={2}>
        </Stack>
      ) : (
        <Stack direction="row" spacing={2} mt={2}>
          <Button
            fullWidth={true}
            variant="contained"
            disabled={loading}
            color="warning"
            onClick={reject}
          >
            Отклонить
          </Button>

          <Button
            fullWidth={true}
            variant="contained"
            color="primary"
            onClick={approve}
            disabled={loading}
            size="large"
          >
            Подтвердить
          </Button>
        </Stack>
      )}

    </Container>
  )
}

export {ViewOperation}
